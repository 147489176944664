@use '../../../../styles/modules.scss' as *;

.titleWrapper {
  width: 400px;
  padding: 12% 20px 114px 30px;
  background-color: $co-cadetblue;
}

.title {
  @include font($fs: rem(34px), $lh: rem(44px), $fw: $fw-bold, $co: $co-white);
}

.logoFacilsure {
  width: 200px;
}

@include media-breakpoint-up(md) {
  .title {
    padding-top: 12%;
  }
}
